import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Supply = ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <SEO title="Поставка материалов" />

    <section id="supply" className="pb80 pt100 bg-dark uslugi supply">

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="section-title mb25">
              <h1>Поставка материалов</h1>
              <p>
                От качества стройматериалов зависит эстетическая привлекательность, надежность и долговечность любого здания или сооружения, а также безопасность людей, которые будут их эксплуатировать. Компания «СК МАСТЕР» осуществляет поставку материалов для отделки и строительства по доступным ценам и с гарантией соблюдения сроков.
              </p>
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h2 className="color-style2">
              Что мы предлагаем
              </h2>
            <p>У нас Вы найдете любые материалы для ремонта, строительства, реконструкции или благоустройства. В ассортименте «СК МАСТЕР» представлен широкий выбор:</p>
            <ul className="list-group checklist">
              <li>цемента;</li>
              <li>керамогранита;</li>
              <li>искусственного и натурального камня;</li>
              <li>кирпича;</li>
              <li>гипсокартона;</li>
              <li>герметиков и антисептиков;</li>
              <li>строительных смесей;</li>
              <li>утеплителей и т.д.</li>
            </ul>
            <p>
              При затруднениях с выбором, Вы всегда можете связаться с нашими специалистами, которые с радостью помогут Вам выбрать подходящий стройматериал и оформить заказ.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h2 className="color-style2">
              Почему работать с нами выгодно
              </h2>
            <p>Клиенты компании «СК МАСТЕР» - частные застройщики и организации, которые никогда не имеют простоев в работе из-за задержки поставки материалов. Работать с нами не просто удобно, но и выгодно. Вот лишь часть «плюсов» сотрудничества с «СК МАСТЕР»:</p>
            <ul className="list-group checklist">
              <li>надежность. Все наши стройматериалы имеют сертификаты и изготовлены из качественного сырья на современном оборудовании;</li>
              <li>доступные цены. Налаженные связи с производителями/поставщиками, продуманная логистика поставок и большой опыт в отрасли дают нам возможность поставлять стройматериалы по минимальной стоимости;</li>
              <li>«НЕТ!» срыву сроков. Мы понимаем, что строительство должно проходит без перерывов, поэтому готовы обеспечить стабильные поставки стройматериалов на площадку.</li>
           </ul>
            <p>
              Для получения более подробной информации о наличии материалов на складе, обращайтесь к нашим менеджерам.
            </p>
          </div>
        </div>
        <p className='exclamation'>
          «СК МАСТЕР» - и нет проблем с поставкой материалов!
        </p>
      </div>
    </section>

  </Layout >
)

export default Supply
